import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Img = makeShortcode("Img");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "communication-styles"
    }}>{`Communication styles`}</h1>
    <p>{`These tables shows three different communication styles.`}</p>
    <Grid container spacing={1} mdxType="Grid">
  <Grid item xs={12} sm={4} mdxType="Grid">
    <Img src="/images/m5/26-03-MCB.svg" alt="Passive Expression" mdxType="Img" />
    <table>
      <thead>
        <tr>
          <th>Passive</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Looks down and avoids eye contact</td>
        </tr>
        <tr>
          <td>Talks with hesitation</td>
        </tr>
        <tr>
          <td>Uses a ‘singsong’ or whining tone in a quiet voice</td>
        </tr>
        <tr>
          <td>
            Uses justifications, e.g. “I wouldn’t normally say anything” and fill-in words, e.g. “Maybe”, “Er”, “Sort
            of”
          </td>
        </tr>
        <tr>
          <td>Apologises frequently, e.g. “I’m terribly sorry to bother you...”</td>
        </tr>
        <tr>
          <td>Uses qualifiers, e.g. “It’s only my opinion”; or “I might be wrong”</td>
        </tr>
        <tr>
          <td>Dismisses own opinion, e.g. “It’s not important”; or “It doesn’t really matter”</td>
        </tr>
        <tr>
          <td>Puts themself down, e.g. “I’m useless...hopeless”; or “You know me...”</td>
        </tr>
        <tr>
          <td>Leaves the person feeling uncertain and frustrated.</td>
        </tr>
      </tbody>
    </table>
  </Grid>
  <Grid item xs={12} sm={4} mdxType="Grid">
    <Img src="/images/m5/26-02-MCB.svg" alt="Assertive Expression" mdxType="Img" />
    <table>
      <thead>
        <tr>
          <th>Assertive</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Makes direct eye contact without staring</td>
        </tr>
        <tr>
          <td>Speaks in a steady, even pace with few hesitations</td>
        </tr>
        <tr>
          <td>Uses “I” statements (“I like”, “I want”, “I don’t like”) that are brief and to the point</td>
        </tr>
        <tr>
          <td>Uses a warm tone that is not overly loud or quiet</td>
        </tr>
        <tr>
          <td>Uses co-operative phrases, e.g. “What are your thoughts on this?”</td>
        </tr>
        <tr>
          <td>Expresses a willingness to explore other solutions, e.g. “How can we get around this problem?”</td>
        </tr>
        <tr>
          <td>Uses distinction between fact and opinion, e.g. “My experience is different”</td>
        </tr>
        <tr>
          <td>
            Gives constructive criticism without blame, e.g. “I feel frustrated when you take so long to get ready for
            school”
          </td>
        </tr>
        <tr>
          <td>Leaves the person feeling respected and understood.</td>
        </tr>
      </tbody>
    </table>
  </Grid>
  <Grid item xs={12} sm={4} mdxType="Grid">
    <Img src="/images/m5/26-01-MCB.svg" alt="Aggressive Expression" mdxType="Img" />
    <table>
      <thead>
        <tr>
          <th>Aggressive</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Stares down and intimidates</td>
        </tr>
        <tr>
          <td>Speaks in a fast, abrupt and clipped manner</td>
        </tr>
        <tr>
          <td>Uses a sarcastic, cold and harsh tone that is forceful and loud.</td>
        </tr>
        <tr>
          <td>Uses ‘You’ statements and threats, e.g. “You’d better watch out”; or “If you don’t…”</td>
        </tr>
        <tr>
          <td>Uses put downs, e.g. “You’ve got to be kidding…”; or “Don’t be so stupid”</td>
        </tr>
        <tr>
          <td>Tells people what to do, e.g. “This is how it’s going to be from now on”</td>
        </tr>
        <tr>
          <td>
            Expresses opinions as facts, e.g. “Nobody wants to behave like that”; or “That’s a useless way to do it”
          </td>
        </tr>
        <tr>
          <td>Uses sarcasm, e.g. “You must be joking”; or “I don’t suppose you managed to do that?”</td>
        </tr>
        <tr>
          <td>Leaves the person feeling resentful and bullied.</td>
        </tr>
      </tbody>
    </table>
  </Grid>
    </Grid>
    <p>{`Which column best describes how you communicate with others? Try using as many assertive skills as possible in everyday situations!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      